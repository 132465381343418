<template>
  <div class="category-tab">
    <div class="subheader mb-2">Date Range</div>
    <div class="mb-3">
      <select
        name="filters"
        class="form-select"
        @change="changedFilter($event)"
      >
        <option value="">All</option>
        <option
          v-for="filter in allFilters"
          v-bind:key="filter"
          :value="filter"
          :selected="selected === filter"
        >
          {{ filter }}
        </option>
      </select>
    </div>
  </div>
</template>

<style lang="scss">
.trash-on-hover {
  .hide-on-hover {
    display: block;
  }

  .on-hover {
    display: none;
  }
}
.trash-on-hover:hover {
  background: rgba(255, 255, 255, 0.9);

  .hide-on-hover {
    display: none;
  }

  .on-hover {
    display: block;
  }
}
</style>

<script>
export default {
  name: "FilterSelect",
  data: () => {
    return {
      selected: "",
      dateFilter: "",
    };
  },
  props: {
    allFilters: {
      type: Array,
      default: () => [],
    },
    bus: String,
  },
  methods: {
    changedFilter(event) {
      let filter = event.target.value;
      this.dateFilter = filter;
      this.selected = filter;
      this.$emit("update:selected", this.selected);
      event.target.blur();
    },
    unselectedFilter() {
      this.selected = "";
      this.$emit("update:selected", this.selected);
    },
  },
  mounted() {
    this.selected = this.bus;
    this.dateFilter = this.bus;
  },
};
</script>
